import Constants from '@constants'
import GroupBy from 'lodash/groupBy'
import Invert from 'lodash/invert'
import SortBy from 'lodash/sortBy'
import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'
import { transformChartForServer, transformChart } from './dashboard'
import { getDecryptedPassword } from '@utils/password'

const reportTypeMap = {
  tabular: 'DataReportDefinitionRest',
  matrix: 'MatrixReportDefinitionRest',
  summary: 'SummaryReportRest',
  plugin: 'PluginReportDefinitionRest',
  query: 'QueryReportDefinitionRest',
}

function transformTabularColumnForServer(column) {
  const numberFunctionsValue = column.numberFunctionsValue || []
  return {
    propKey: column.key,
    orderNumber: column.order,
    showSum: numberFunctionsValue.indexOf('sum') >= 0,
    showAvg: numberFunctionsValue.indexOf('avg') >= 0,
    showUniqCount: numberFunctionsValue.indexOf('count') >= 0,
    showMinValue: numberFunctionsValue.indexOf('min') >= 0,
    showMaxValue: numberFunctionsValue.indexOf('max') >= 0,
    showTotalCount: numberFunctionsValue.indexOf('total_count') >= 0,
  }
}

function transformMatrixColumn(column) {
  return {
    key: column.propKey,
    orderNumber: column.order,
    aggregateFunction: column.aggregatedFunction,
    ...(column.selectedValues
      ? {
          values: column.selectedValues || [],
        }
      : {}),
  }
}

function transformSummaryColumn(column) {
  return {
    key: column.sectionKey,
    matchQualified: column.matchQualifiedData,
    ...(column.selectedColumnKeys
      ? {
          values: column.selectedColumnKeys,
        }
      : {}),
  }
}

function transformTabularColumn(column) {
  const numberFunctionsValue = []
  if (column.showSum) {
    numberFunctionsValue.push('sum')
  }
  if (column.showAvg) {
    numberFunctionsValue.push('avg')
  }
  if (column.showUniqCount) {
    numberFunctionsValue.push('count')
  }
  if (column.showMinValue) {
    numberFunctionsValue.push('min')
  }
  if (column.showMaxValue) {
    numberFunctionsValue.push('max')
  }
  if (column.showTotalCount) {
    numberFunctionsValue.push('total_count')
  }
  return { key: column.propKey, numberFunctionsValue }
}

function transformMatrixColumnForServer(column) {
  return {
    propKey: column.key,
    orderNumber: column.order,
    aggregatedFunction: column.aggregateFunction,
    ...(column.values
      ? {
          selectedValues: column.values,
        }
      : {}),
  }
}

function transformSummaryColumnForServer(column) {
  return {
    sectionKey: column.key,
    orderNumber: column.order,
    matchQualifiedData: column.matchQualified,
    ...(column.values
      ? {
          selectedColumnKeys: column.values,
        }
      : {}),
  }
}

export const transformReportDetail = (report) => {
  return {
    ...(report.reportDetail
      ? {
          assetTypeId: report.reportDetail.assetTypeId,
          serviceCatalogId: report.reportDetail.serviceCatalogId,
          patchType: report.reportDetail.patchType,
          remoteDeploymentType: report.reportDetail.remoteDeploymentType,
          // co-relation report fields
          coRelatedAssetTypeId: report.reportDetail.coRelatedAssetTypeId,
          coRelatedPatchType: report.reportDetail.coRelatedPatchType,
          coRelatedModuleName: report.reportDetail.coRelatedModel,
          coRelatedQualifications: transformQualification(
            report.reportDetail.coRelatedQualification
          ),
        }
      : {}),
  }
}

export const transformReportDetailForServer = (report) => {
  const reportDetail = {
    ...(report.assetTypeId &&
    [
      Constants.ASSET,
      Constants.ASSET_HARDWARE,
      Constants.ASSET_SOFTWARE,
      Constants.ASSET_NON_IT,
      Constants.ASSET_CONSUMABLE,
    ].indexOf(report.moduleName) >= 0
      ? {
          type: 'AssetReportDetailRest',
          assetTypeId: report.assetTypeId,
          // co-relation report fields
          coRelatedAssetTypeId: report.coRelatedAssetTypeId,
          coRelatedModel: report.coRelatedModuleName,
          coRelatedQualification: transformQualificationForServer(
            report.coRelatedQualifications
          ),
        }
      : {}),
    ...(report.serviceCatalogId &&
    report.moduleName === Constants.SERVICE_CATALOG
      ? {
          type: 'ServiceCatalogReportDetailRest',
          serviceCatalogId: report.serviceCatalogId,
        }
      : {}),
    ...(report.patchType && report.moduleName === Constants.PATCH
      ? {
          type: 'PatchReportDetailRest',
          patchType: report.patchType,
          // co-relation report fields
          coRelatedPatchType: report.coRelatedPatchType,
          coRelatedModel: report.coRelatedModuleName,
          coRelatedQualification: transformQualificationForServer(
            report.coRelatedQualifications
          ),
        }
      : {}),
    ...(report.remoteDeploymentType &&
    report.moduleName === Constants.REMOTE_DEPLOYMENT
      ? {
          type: 'RemoteDeploymentReportDetailRest',
          remoteDeploymentType: report.remoteDeploymentType,
        }
      : {}),
  }
  if (Object.keys(reportDetail).length > 0) {
    return {
      reportDetail,
    }
  }
  return {}
}

export function transformReporQualification(report) {
  if (!report.updatebleOob) {
    if (
      report.qualification &&
      report.qualification.type === 'RelationalQualificationRest'
    ) {
      const qualification = {
        type: 'BatchQualificationRest',
        quals: [report.qualification],
        operator: 'and',
      }
      return transformQualification(qualification)
    } else {
      return transformQualification(report.qualification)
    }
  } else {
    return transformQualification(report.qualification)
  }
}

export function transformReport(report) {
  const invertMap = Invert(reportTypeMap)
  let columnTransformer
  if (invertMap[report.type] === 'tabular') {
    columnTransformer = transformTabularColumn
  }
  if (invertMap[report.type] === 'matrix') {
    columnTransformer = transformMatrixColumn
  }
  if (invertMap[report.type] === 'summary') {
    columnTransformer = transformSummaryColumn
  }
  return {
    id: report.id,
    name: report.name,
    dateFilter: report.dateFilterProp,
    dateFilterSupported: report.dateFilterSupported,
    moduleName: report.model,
    description: report.description,
    reportType: invertMap[report.type],
    // layout: report.reportLayout,
    technicians: report.userIds,
    technicianGroups: report.groupIds,
    createdAt: report.createdTime,
    owner: report.createdById,
    pluginIdentifier: report.pluginIdentifier,
    ...(invertMap[report.type] === 'query'
      ? { customQuery: report.customQuery, ...transformReportDetail(report) }
      : {
          chart: {
            enabled: report.chartDetails.includeChart,
            ...transformChart(report.chartDetails),
          },
          ...transformReportDetail(report),
          ...(invertMap[report.type] === 'matrix'
            ? {
                top: report.top,
                sortOrder:
                  (report.reportSort || {}).sort === 'none'
                    ? undefined
                    : (report.reportSort || {}).sort,
                ...((report.reportSort || {}).columnKey
                  ? { sortByColumn: report.reportSort.columnKey }
                  : {}),
                ...((report.reportSort || {}).columnValue
                  ? { sortByColumnValue: report.reportSort.columnValue }
                  : {}),
              }
            : {}),
          ...(report.top ? { topCount: report.top } : {}),
          qualifications: transformReporQualification(report), // transformQualification(report.qualification),
          groupBy: report.groupByColumnPropKey,
          ...(invertMap[report.type] !== 'plugin'
            ? {
                columns: SortBy(
                  report[
                    invertMap[report.type] === 'summary'
                      ? 'sections'
                      : 'columns'
                  ],
                  'orderNumber'
                ).map(columnTransformer),
              }
            : { columns: [] }),
        }),
    canEdit: report.updatebleOob,
    canDelete: report.deleteableOob,
  }
}

export function transformReportForServer(report) {
  let columnTransformer = transformTabularColumnForServer
  if (report.reportType === 'tabular') {
    columnTransformer = transformTabularColumnForServer
  }
  if (report.reportType === 'matrix') {
    columnTransformer = transformMatrixColumnForServer
  }
  if (report.reportType === 'summary') {
    columnTransformer = transformSummaryColumnForServer
  }
  return {
    name: report.name,
    model: report.moduleName,
    dateFilterProp: report.dateFilter || null,
    dateFilterSupported: report.dateFilterSupported,
    description: report.description,
    type: reportTypeMap[report.reportType],
    // reportLayout: report.layout,
    userIds: report.technicians || [],
    groupIds: report.technicianGroups || [],
    ...(report.reportType === 'query'
      ? {
          customQuery: report.customQuery,
          ...transformReportDetailForServer(report),
        }
      : {
          chartDetails: {
            includeChart: report.chart.enabled || false,
            ...transformChartForServer(report.chart),
          },
          ...transformReportDetailForServer(report),
          ...(report.reportType === 'matrix'
            ? {
                reportSort: {
                  sort: report.sortOrder ? report.sortOrder : 'none',
                  ...(report.sortByColumn
                    ? { columnKey: report.sortByColumn }
                    : { columnKey: null }),
                  ...(report.sortByColumnValue
                    ? { columnValue: report.sortByColumnValue }
                    : { columnValue: null }),
                },
              }
            : {}),
          ...(report.topCount && report.sortOrder
            ? { top: report.topCount }
            : { top: null }),
          qualification: transformQualificationForServer(report.qualifications),
          groupByColumnPropKey: report.groupBy || null,
          [report.reportType === 'summary' ? 'sections' : 'columns']:
            report.columns.map((c, index) =>
              columnTransformer({
                ...c,
                order: index + 1,
              })
            ),
          ...(report.reportType === 'plugin'
            ? { pluginIdentifier: report.pluginIdentifier }
            : {}),
        }),
  }
}

export function transformReportForList(report) {
  const invertMap = Invert(reportTypeMap)
  return {
    id: report.id,
    name: report.name,
    type: invertMap[report.type],
    moduleName: report.model,
    dateFilter: report.dateFilterProp,
    createdBy: report.createdById,
    createdAt: report.createdTime,
    canEdit: report.updatebleOob,
    canDelete: report.deleteableOob,
    enabled: report.reportScheduler ? !report.reportScheduler.disabled : false,
  }
}

export const ScheduleTypeMap = {
  once: 'FlotoOnceScheduleRest',
  daily: 'FlotoDailyScheduleRest',
  weekly: 'FlotoWeeklyScheduleRest',
  monthly: 'FlotoMonthlyScheduleRest',
  interval: 'FlotoIntervalScheduleRest',
}

export const tranformFlotoScheduleForServer = (schedule) => {
  return {
    type: ScheduleTypeMap[schedule.frequency],
    ...(schedule.recurringHour || schedule.recurringHour === 0
      ? { recurringStartHour: schedule.recurringHour }
      : {}),
    ...(schedule.startAt ? { startAt: schedule.startAt } : {}),
    ...(schedule.recurringDay || schedule.recurringDay === 0
      ? { daysOfWeek: schedule.recurringDay }
      : {}),
    ...(schedule.recurringDate ? { dateOfMonth: schedule.recurringDate } : {}),
    ...(schedule.recurringMonth
      ? { monthsOfYear: schedule.recurringMonth }
      : {}),
    ...(schedule.timeInterval
      ? {
          timeInterval: schedule.timeInterval,
          timeIntervalUnit: schedule.timeIntervalUnit,
        }
      : {}),
  }
}

export const transformFlotoSchedule = (schedule) => {
  const invertScheduleMap = Invert(ScheduleTypeMap)
  if (!invertScheduleMap[schedule.flotoSchedule.type]) {
    throw new Error(`${schedule.flotoSchedule.type} is not found in map`)
  }
  return {
    frequency: invertScheduleMap[schedule.flotoSchedule.type],
    ...(schedule.flotoSchedule.recurringStartHour ||
    schedule.flotoSchedule.recurringStartHour === 0
      ? { recurringHour: schedule.flotoSchedule.recurringStartHour }
      : {}),
    ...(schedule.flotoSchedule.startAt
      ? { startAt: schedule.flotoSchedule.startAt }
      : {}),
    ...(schedule.flotoSchedule.daysOfWeek ||
    schedule.flotoSchedule.daysOfWeek === 0
      ? { recurringDay: schedule.flotoSchedule.daysOfWeek }
      : {}),
    ...(schedule.flotoSchedule.dateOfMonth
      ? { recurringDate: schedule.flotoSchedule.dateOfMonth }
      : {}),
    ...(schedule.flotoSchedule.monthsOfYear
      ? { recurringMonth: schedule.flotoSchedule.monthsOfYear }
      : {}),
    ...(schedule.flotoSchedule.timeInterval
      ? {
          timeInterval: schedule.flotoSchedule.timeInterval,
          timeIntervalUnit: schedule.flotoSchedule.timeIntervalUnit,
        }
      : {}),
  }
}

export function transformReportScheduleForServer(schedule) {
  if (!ScheduleTypeMap[schedule.frequency]) {
    throw new Error(`${schedule.frequency} is not found in map`)
  }
  return {
    id: schedule.id,
    name: schedule.name,
    disabled: schedule.enabled === false,
    emailAddresses: schedule.emails,
    type: schedule.type,
    userIds: schedule.userIds || [],
    groupIds: schedule.groupIds || [],
    reportExportFormat: schedule.exportFormat,
    flotoSchedule: tranformFlotoScheduleForServer(schedule),
    flotoDateFilterInfo: {
      lastXDays: schedule.lastOfDays,
    },
    passwordProtected: schedule.passwordProtected,
    attachmentPassword: schedule.protectedPassword,
  }
}

export function transformReportSchedule(schedule) {
  return {
    id: schedule.id,
    name: schedule.name,
    enabled: schedule.disabled === false,
    emails: schedule.emailAddresses,
    exportFormat: schedule.reportExportFormat,
    userIds: schedule.userIds,
    groupIds: schedule.groupIds,
    type: schedule.type,
    canEdit: schedule.updatebleOob,
    canDelete: schedule.deleteableOob,
    passwordProtected: schedule.passwordProtected,
    protectedPassword: schedule.attachmentPassword
      ? getDecryptedPassword(schedule.attachmentPassword)
      : '',
    ...transformFlotoSchedule(schedule),
    lastOfDays: (schedule.flotoDateFilterInfo || {}).lastXDays,
  }
}

export function transformTabularReportPreview(preview) {
  const table = {
    header: [],
    body: [],
    columns: [],
  }
  const headerRow = {}
  if ((preview.groupList || []).length) {
    preview.groupList[0].columnList.map((c) => {
      headerRow[c.columnKey] = {
        key: c.columnKey,
        text: c.title,
      }
    })
    table.header.push(headerRow)
    preview.groupList.forEach((group) => {
      if (group.columnList[0].data.length === 0) {
        return true
      }
      if (group.columnKey) {
        // add full width group header
        table.body.push({
          [group.columnKey]: {
            key: group.columnKey,
            text: `${preview.groupTitle}: ${group.title}`,
            colspan: group.columnList.length,
            tabularColumnGroupRow: true,
          },
        })
      }
      // add rows for group
      const groupDataMap = {}
      group.columnList.forEach((c) => {
        groupDataMap[c.columnKey] = c.data
      })
      group.columnList[0].data.forEach((dataList, index) => {
        const row = {}
        Object.keys(headerRow).forEach((key) => {
          row[headerRow[key].key] = {
            key: headerRow[key].key,
            text: groupDataMap[headerRow[key].key][index],
          }
          if (table.columns.indexOf(headerRow[key].key) === -1) {
            table.columns.push(headerRow[key].key)
          }
        })
        table.body.push(row)
      })
    })
    numberFunctionReportTablePreview(table, preview).forEach((data) => {
      data.rows.forEach((summaryRow) => {
        table.body.push({
          [table.columns[0]]: {
            key: table.columns[0],
            colspan: table.columns.length,
            text: data.title,
            tabularColumnGroupRow: true,
          },
        })
        table.body.push(summaryRow)
      })
    })
  }
  return table.header.length && table.body.length ? table : undefined
}

const numberFunctionColumnKeys = [
  { key: 'sumWithColumnKey', text: 'Sum' },
  { key: 'avgWithColumnKey', text: 'Average' },
  { key: 'uniqValueWithColumnKey', text: 'Count' },
  { key: 'minValueWithColumnKey', text: 'Minimum Value' },
  { key: 'maxValueWithColumnKey', text: 'Maximum Value' },
  { key: 'totalCountWithColumnKey', text: 'Total Count' },
]

export function numberFunctionReportTablePreview(tableData, previewData) {
  const numberTablesList = []
  numberFunctionColumnKeys.forEach((numberColumn) => {
    if (
      previewData[numberColumn.key] &&
      Object.keys(previewData[numberColumn.key]).length
    ) {
      const headerRow = tableData.header[0]
      const row = {}
      Object.keys(headerRow).forEach((key) => {
        row[headerRow[key].key] = {
          key: headerRow[key].key,
          text:
            previewData[numberColumn.key][headerRow[key].key] ||
            previewData[numberColumn.key][headerRow[key].key] === 0
              ? previewData[numberColumn.key][headerRow[key].key]
              : '---',
        }
        if (tableData.columns.indexOf(headerRow[key].key) === -1) {
          tableData.columns.push(headerRow[key].key)
        }
      })
      const table = {
        rows: [],
        title: numberColumn.text,
      }
      table.rows.push(row)
      numberTablesList.push(table)
    }
  })
  return numberTablesList
}

export function transformMatrixReportPreview(preview) {
  const table = {
    matrixHeader: [],
    header: [],
    body: [],
    columns: [],
  }
  if ((preview.groupList || []).length) {
    // build header row with group by in row span and section title with colspans
    const headerRow = {
      [preview.groupList[0].columnKey]: {
        text: preview.groupTitle,
        key: preview.groupTitle,
        rowspan: 2,
      },
    }
    const groupsForColumns = GroupBy(
      preview.groupList[0].columnList,
      'parentTitle'
    )
    Object.keys(groupsForColumns).forEach((groupTitle) => {
      const key = groupsForColumns[groupTitle][0].columnKey.split('&&')[0]
      headerRow[key] = {
        text: groupTitle,
        key,
        colspan: (preview.supportedColSpan || {})[key]
          ? groupsForColumns[groupTitle].length
          : undefined,
        rowspan: (preview.supportedColSpan || {})[key] ? undefined : 2,
      }
    })
    table.matrixHeader.push(headerRow)
    const dataColumns = {}
    preview.groupList[0].columnList.forEach((c) => {
      const key = c.columnKey.split('&&')[0]
      if (headerRow[key] && headerRow[key].rowspan === undefined) {
        dataColumns[c.columnKey] = {
          key: c.columnKey,
          text: c.title,
        }
      }
    })
    table.header.push(dataColumns)
    // build table body rows

    preview.groupList.forEach((group) => {
      const row = {
        [group.columnKey]: {
          key: group.columnKey,
          text: group.title,
          headerColumn: true,
        },
      }
      if (table.columns.indexOf(group.columnKey) === -1) {
        table.columns.push(group.columnKey)
      }
      group.columnList.forEach((c) => {
        row[c.columnKey] = {
          key: c.columnKey,
          text: c.data[0],
        }
        if (table.columns.indexOf(c.columnKey) === -1) {
          table.columns.push(c.columnKey)
        }
      })
      table.body.push(row)
    })
  }
  return table
}

export function transformSummaryReportPreview(reports) {
  const reportGroupBy = GroupBy(reports, 'title')
  const previewData = Object.keys(reportGroupBy).map((report) => {
    const list = reportGroupBy[report] || []
    const tableList = list.map((preview) => {
      const previewData = { summayAsMatrix: false }
      const table = {
        matrixHeader: [],
        header: [],
        body: [],
        columns: [],
      }
      if ((preview.groupList || []).length) {
        const headerRow = {}
        const matrixHeaderRow = {}
        if (preview.groupList[0].columnList[0].parentTitle) {
          const groupsForColumns = GroupBy(
            preview.groupList[0].columnList,
            'parentTitle'
          )
          if (Object.keys(groupsForColumns).length) {
            previewData.summayAsMatrix = true
            Object.keys(groupsForColumns).forEach((groupTitle) => {
              matrixHeaderRow[groupTitle] = {
                text: groupTitle,
                key: groupTitle,
                colspan: groupsForColumns[groupTitle].length,
                rowspan: undefined,
              }
            })
            table.matrixHeader.push(matrixHeaderRow)
          }
        }
        preview.groupList[0].columnList.map((c) => {
          headerRow[c.columnKey] = {
            key: c.columnKey,
            text: c.title,
          }
        })
        table.header.push(headerRow)
        preview.groupList.forEach((group) => {
          previewData.groupTitle = group.title
          if (group.columnList[0].data.length === 0) {
            return true
          }
          // add rows for group
          const groupDataMap = {}
          group.columnList.forEach((c) => {
            groupDataMap[c.columnKey] = c.data
          })
          group.columnList[0].data.forEach((dataList, index) => {
            const row = {}
            Object.keys(headerRow).forEach((key) => {
              row[headerRow[key].key] = {
                key: headerRow[key].key,
                text: groupDataMap[headerRow[key].key][index],
              }
              if (table.columns.indexOf(headerRow[key].key) === -1) {
                table.columns.push(headerRow[key].key)
              }
            })
            table.body.push(row)
          })
        })
      }
      return table.header.length && table.body.length
        ? { table, ...previewData }
        : undefined
    })
    return {
      title: report,
      groupList: tableList.filter((e) => e),
    }
  })
  return previewData
}

export function transformAvailableColumn(c) {
  return {
    text: c.displayName,
    key: c.propKey,
    selectableColumn: c.column,
    groupBy: c.groupBy,
    groupName: c.groupName,
    sorting: true, // c.sorting,
    aggregateFunctions: c.supportedFuncation || [],
    supportedNumericFunctions: c.supportedNumericFunction || [],
    hasValueSelection: c.selectionValue,
  }
}

export function transformReportHistory(history) {
  return {
    id: history.id,
    name: history.name,
    createdBy: history.createdById,
    dateFilter: history.dateFilterProp,
    eventTime: history.createdTime,
    startTime: history.startTime,
    endTime: history.endTime,
    reportFormat: history.exportFormat,
    eventType: history.eventType,
    emailAddresses: history.emailAddresses,
    technicians: history.userIds,
    groups: history.groupIds,
    fileName: history.fileName,
  }
}

export function transformDownloadForServer(data) {
  return {
    exportFormat: data.exportFormat,
    exportDisplayName: data.exportDisplayName,
    columns: data.columns,
    sortColumnKey: data.sortColumnKey,
    sortType: data.sortType,
    qualification: data.qualification,
    model: data.model,
    reportDetailRest: data.reportDetailRest,
    attachmentPassword: data.attachmentPassword,
    type: 'ExportGridDataRest',
  }
}

export function transformExportGridDataForServer(data) {
  return {
    ...data,
    type: 'ExportGridDataRest',
  }
}
