export function transformAddressForServer(address) {
  return {
    streetLine1: address.streetLine1,
    streetLine2: address.streetLine2,
    city: address.city,
    state: address.state,
    country: address.country,
    zipCode: address.zipCode,
  }
}
export function transformAddress(address) {
  return {
    streetLine1: address.streetLine1,
    streetLine2: address.streetLine2,
    city: address.city,
    state: address.state,
    country: address.country,
    zipCode: address.zipCode,
  }
}
export function transformAssetMovement(movement) {
  return {
    id: movement.id,
    name: movement.name,
    displayName: movement.displayName,
    approvalStatus: movement.approvalStatus,
    movementType: movement.movementType,
    requesterId: movement.requesterId,
    fromLocation: movement.fromLocation,
    toLocation: movement.toLocation,
    movementDate: movement.movementDate,
    expectedDate: movement.expectedDate,
    assetIds: movement.assetIds,
    assetType: movement.assetType,
    barCode: movement.barCode,
    assetTypeId: movement.assetTypeId,
    vendorId: movement.vendorId,
    referenceNumber: movement.referenceNumber,
    returnableType: movement.returnableType,
    createdById: movement.createdById,
    createdTime: movement.createdTime,
    updatedTime: movement.updatedTime,
    closedDate: movement.closedDate,
    remark: movement.remark,
    contactNo: movement.contactNo,
    email: movement.email,
    statusId: movement.status,
    prevStatusId: movement.previousStatus,
    fileAttachments: movement.fileAttachments,
    model: movement.model,
    assetCondition: movement.assetCondition,
    solution: movement.solution,
    // address: transformAddress(movement.address || {}),
    address: {
      street: movement.streetLine1,
      landmark: movement.streetLine2,
      city: movement.city,
      pinCode: movement.zipCode,
      state: movement.state,
      country: movement.country,
    },
    fromDepartment: movement.fromDepartment,
    toDepartment: movement.toDepartment,
    draft: movement.draft,
  }
}
export function transformAssetMovementForServer(movement) {
  // const listProps = transformAssetMovement(movement)
  // return {
  //   ...listProps,
  // }
  const transformedProps = ['fieldValueDetails', 'address']
  var obj = {
    ...(movement.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...movement.fieldValueDetails,
          },
        }
      : {}),
  }
  if ('address' in movement) {
    obj = {
      ...(movement.address.street
        ? { streetLine1: movement.address.street }
        : {}),
      ...(movement.address.landmark
        ? { streetLine2: movement.address.landmark }
        : {}),
      ...(movement.address.city ? { city: movement.address.city } : {}),
      ...(movement.address.pinCode
        ? { zipCode: movement.address.pinCode }
        : {}),
      ...(movement.address.state ? { state: movement.address.state } : {}),
      ...(movement.address.country
        ? { country: movement.address.country }
        : {}),
    }
  }
  Object.keys(movement).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = movement[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: movement[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = movement[paramName]
    }
  })
  if ('description' in movement) {
    obj.description = movement.description || ''
  }
  if ('statusId' in movement) {
    obj.status = movement.statusId
  }
  return obj
}
export function transformAsset(asset) {
  return {
    id: asset.id,
    name: asset.name,
    assetCondition: asset.assetCondition,
    displayName: asset.displayName,
    assetTypeId: asset.assetTypeId,
    statusId: asset.statusId,
    barCode: asset.barCode,
    inWarranty: asset.inWarranty,
    movementDate: asset.movementDate,
    vendorId: asset.vendorId,
    remarks: asset.remarks,
    warrantyCovered: asset.warrantyCovered,
    expectedDate: asset.expectedDate,
    referenceNumber: asset.referenceNumber,
    replaced: asset.replaced,
    replaceBy: asset.replaceBy,
    model: asset.model,
    fault: asset.fault,
    repairType: asset.repairType,
    repairCost: asset.repairCost,
    solution: asset.solution,
  }
}

export function transformMovementAndRepairHistory(history) {
  return {
    id: history.id,
    name: history.name,
    fromLocation: history.fromLocation,
    toLocation: history.toLocation,
    fromDepartment: history.fromDepartment,
    toDepartment: history.toDepartment,
    returnableType: history.returnableType,
    movementIn: history.movementIn,
    movementInName: history.movementInName,
    movementType: history.movementType,
    requesterId: history.requesterId,
    referenceNumber: history.referenceNumber,
    vendorId: history.vendorId,
    movementDate: history.movementDate,
    expectedDate: history.expectedDate,
    inWarranty: history.inWarranty,
    remarks: history.remarks,
    movementOut: history.movementOut,
    movementOutName: history.movementOutName,
    returnDate: history.returnDate,
    fault: history.fault,
    vendorEmail: history.vendorEmail,
    contectNo: history.contectNo,
    repairType: history.repairType,
    statusId: history.status,
    repairCost: history.repairCost,
    solution: history.solution,
    replaceBy: history.replaceBy,
    replaced: history.replaced,
    warrantyCovered: history.warrantyCovered,
    refModel: history.refModel,
  }
}
