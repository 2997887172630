export function transformTenantPrefrences(config) {
  return {
    sendUserActivationMail: config.sendUserActivationMail,
    baseUrl: config.baseUrl,
    considerLastLoginAsUsedBy: config.considerLastLoginAsUsedBy,
    assetBarcodePrefix: config.assetBarcodePrefix,
    softwareAssetPrefix: config.softwareAssetPrefix,
    nonItAssetPrefix: config.nonItAssetPrefix,
    consumableAssetPrefix: config.consumableAssetPrefix,
    hardwareAssetPrefix: config.hardwareAssetPrefix,
    barcodeGenerationStrategy: config.barcodeGenerationStrategy,
    assetUniqueIdentifierSshPropKeys: config.assetUniqueIdentifierSshPropKeys,
    assetUniqueIdentifierWmiPropKeys: config.assetUniqueIdentifierWmiPropKeys,
    assetUniqueIdentifierSnmpPropKeys: config.assetUniqueIdentifierSnmpPropKeys,
    allowTechnicianToCreateRequestOnBehalfOfGuest:
      config.allowTechnicianToCreateRequestOnBehalfOfGuest,
    populateLastConversationInReply: config.populateLastConversationInReply,
    considerEmailAsFirstResponse: config.considerEmailAsFirstResponse,
    mergeRequestStatusId: config.mergeRequestStatusId,
    defaultSoftwareType: config.defaultSoftwareType,
    shouldFetchSoftwares: config.shouldFetchSoftwares,
    changeStatusInAgentLessDiscovery: config.changeStatusInAgentLessDiscovery,
    changeStatusInAgentBasedDiscovery: config.changeStatusInAgentBasedDiscovery,
    changeAssetTypeInAgentLessDiscovery:
      config.changeAssetTypeInAgentLessDiscovery,
    changeAssetTypeInAgentBasedDiscovery:
      config.changeAssetTypeInAgentBasedDiscovery,
    shouldOverrideAssetDisplayName: config.shouldOverrideAssetDisplayName,
    sharedFolderDetails: config.sharedFolderDetails,
    fetchUsbPortDetails: config.fetchUsbPortDetails,
    autoAskForApproval: config.autoAskForApproval,
    maxFileSizeAllowed: config.maxFileSizeAllowed,
    enableApplicatonAccessibility: config.enableApplicatonAccessibility,
    blockFileAttachment: config.blockFileAttachment,
    allowTechnicianToChangeDoNotDisturb:
      config.allowTechnicianToChangeDoNotDisturb,
    defaultDashboardId: config.defaultDashboardId,
    emailCommand: config.emailCommand,
    qrCodeSupport: config.qrCodeSupport,
    qrCodeMandatoryPropKeys: config.qrCodeMandatoryPropKeys,
    qrCodeCustomfieldKeys: config.qrCodeCustomfieldKeys,
    selfServiceSetting: config.selfServiceSetting,
    enabledLanguages: config.enabledLanguages,
    minQuestionsInSurvey: config.minQuestionsInSurvey,
    minAudienceSize: config.minAudienceSize,
    enableAutoUnistalltionSoftware: config.enableAutoUnistalltionSoftware,
    excludeUsersFromIpRistriction: config.excludeUsersFromIpRistriction,
    autoUnistalltionDays: config.autoUnistalltionDays,
    enableEmailToRequestForGuestUser: config.enableEmailToRequestForGuestUser,
    enableEmailToRequest: config.enableEmailToRequest,
    useReplyToEmailAddressToCreateRequester:
      config.useReplyToEmailAddressToCreateRequester,
    useOriginalSenderAsRequester: config.useOriginalSenderAsRequester,
    closedRequestAction: config.closedRequestAction,
    reopenRequestGracePeriodDays: config.reopenRequestGracePeriodDays,
    purchasePrefix: config.purchasePrefix,
    customFieldSearch: config.customFieldSearch,
    serviceCatalogCustomFieldSearch: config.serviceCatalogCustomFieldSearch,
    conversationSearch: config.conversationSearch,
    enableAssignmentRule: config.enableAssignmentRule,
    thresholdLeaveBalance: config.thresholdLeaveBalance,
    fileAttachmentSearch: config.fileAttachmentSearch,
    softwareAutoUnistallTime: config.softwareAutoUnistallTime,
    softwareAutoUnistallTimeUnit: config.softwareAutoUnistallTimeUnit,
    saveRepliesConversationInDraft: config.saveRepliesConversationInDraft,
    oauthEmailClientUrl: config.oauthEmailClientUrl,
    assetConfirmationByUser: config.assetConfirmationByUser,
    assetTypeIds: config.assetTypeIds,
    acceptancePeriod: config.acceptancePeriod,
    acceptancePeriodTimeUnit: config.acceptancePeriodTimeUnit || 'days',
    reminderInterval: config.reminderInterval,
    reminderIntervalTimeUnit: config.reminderIntervalTimeUnit || 'hours',
    purchaseStartingNumber: config.purchaseStartingNumber,
    hardwareAssetStartingNumber: config.hardwareAssetStartingNumber,
    softwareAssetStartingNumber: config.softwareAssetStartingNumber,
    nonItAssetStartingNumber: config.nonItAssetStartingNumber,
    consumableAssetStartingNumber: config.consumableAssetStartingNumber,
    purchaseSequenceNumberPadding: config.purchaseSequenceNumberPadding,
    hardwareAssetSequenceNumberPadding:
      config.hardwareAssetSequenceNumberPadding,
    softwareAssetSequenceNumberPadding:
      config.softwareAssetSequenceNumberPadding,
    nonItAssetSequenceNumberPadding: config.nonItAssetSequenceNumberPadding,
    consumableAssetSequenceNumberPadding:
      config.consumableAssetSequenceNumberPadding,
    purchaseCurrentNumber: config.purchaseCurrentNumber,
    hardwareAssetCurrentNumber: config.hardwareAssetCurrentNumber,
    softwareAssetCurrentNumber: config.softwareAssetCurrentNumber,
    nonItAssetCurrentNumber: config.nonItAssetCurrentNumber,
    consumableAssetCurrentNumber: config.consumableAssetCurrentNumber,
    softwareRuleType: config.softwareRuleType,
    requesterPickerSupportedSearchField:
      config.requesterPickerSupportedSearchField,
    hardwareAssetNameFormat: config.hardwareAssetNameFormat,
    softwareAssetNameFormat: config.softwareAssetNameFormat,
    allowUserToSelectFromEmail: config.allowUserToSelectFromEmail,
    enableMobilePushNotification: config.enableMobilePushNotification,
    allowUserToReopenClosedTasks: config.allowUserToReopenClosedTasks,
    allowUserToDeleteTasks: config.allowUserToDeleteTasks,
    defaultSupportLevelForRequest: config.defaultSupportLevelForRequest,
    shouldFetchAssetUsedDetails: config.shouldFetchAssetUsedDetails,
    fetchAssetUsedDetailsDays: config.fetchAssetUsedDetailsDays,
    allowBiometricAuthentication: config.allowBiometricAuthentication,
  }
}

export function transformTenantPrefrencesForServer(config) {
  const transformedProps = []
  const obj = {}
  Object.keys(config).forEach((paramName) => {
    if (!transformedProps.includes(paramName)) {
      obj[paramName] = config[paramName]
    }
  })
  return obj
}
