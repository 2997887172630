import Moment from 'moment'
import { FILESERVER_HOST_SUFFIX } from '@modules/patch-management/helpers/utils'

export function transformProxyConfig(config) {
  if (config.proxyType === 'configure_manually') {
    return {
      proxyType: config.proxyType,
      proxyHost: config.proxyHost,
      proxyPort: config.proxyPort,
      userName: config.userName,
      password: config.password,
    }
  }
  if (config.proxyType === 'dmz') {
    return {
      proxyType: config.proxyType,
      dmzHost: config.dmzHost,
      dmzPort: config.dmzPort,
    }
  }
  return {
    proxyType: config.proxyType,
  }
}

export function transformPatch(patch) {
  return {
    enableSchedule: patch.enableSchedule,
    technicianIds: patch.technicianIds,
    lastUpdatedOn: patch.updatedTime,
    lastSyncDate: patch.lastSyncDate,
    patchUpdateCategories: patch.patchUpdateCategories,
    patchSyncOs: patch.patchSyncOs,
    patchLanguage: patch.patchLanguage,
    recurringStartHour: patch.flotoSchedule
      ? patch.flotoSchedule.recurringStartHour
      : 0,
    thirdPartyPatchEnabled: patch.thirdPartyPatchEnabled,
    thirdPartyApplications: patch.thirdPartyApplications,
  }
}

export function transformPatchForServer(patch) {
  return {
    enableSchedule: patch.enableSchedule,
    technicianIds: patch.technicianIds,
    patchUpdateCategories: patch.patchUpdateCategories,
    patchSyncOs: patch.patchSyncOs,
    lastSyncDate: patch.lastSyncDate,
    patchLanguage: patch.patchLanguage,
    ...('recurringStartHour' in patch
      ? {
          flotoSchedule: {
            type: 'FlotoDailyScheduleRest',
            recurringStartHour: patch.recurringStartHour,
            startAt: Moment().valueOf(),
          },
        }
      : {}),
    thirdPartyPatchEnabled: patch.thirdPartyPatchEnabled,
    thirdPartyApplications: patch.thirdPartyApplications,
  }
}

export function transformSystemHealthSettings(settings) {
  return {
    highlyVulnerableCriticalPatch: settings.highlyVulnerableCriticalPatch,
    highlyVulnerableImportantPatches: settings.highlyVulnerableImportantPatches,
    highlyVulnerableModeratePatch: settings.highlyVulnerableModeratePatch,
    highlyVulnerableLowPatch: settings.highlyVulnerableLowPatch,
    vulnerableCriticalPatch: settings.vulnerableCriticalPatch,
    vulnerableImportantPatches: settings.vulnerableImportantPatches,
    vulnerableModeratePatch: settings.vulnerableModeratePatch,
    vulnerableLowPatch: settings.vulnerableLowPatch,
    onlyApprovedPatch: settings.onlyApprovedPatch,
  }
}

export function transformStorageConfig(config) {
  return {
    host: (config.host || '').replace(FILESERVER_HOST_SUFFIX, ''),
    port: config.port,
    removeSupersededPatches: config.removeSupersededPatches,
    removeOldPatches: config.removeOldPatches,
    notifyTechnicianIds: config.notifyTechnicianIds,
    maxSize: config.maxSize,
    notifyOnSizeExceed: config.notifyOnSizeExceed,
    patchAge: config.patchAge,
  }
}

export function transformStorageConfigForServer(config) {
  return {
    host: `${config.host}${FILESERVER_HOST_SUFFIX}`,
    port: config.port,
    removeSupersededPatches: config.removeSupersededPatches,
    removeOldPatches: config.removeOldPatches,
    notifyTechnicianIds: config.notifyTechnicianIds,
    maxSize: config.maxSize,
    notifyOnSizeExceed: config.notifyOnSizeExceed,
    patchAge: config.patchAge,
  }
}

export function transformComputerGroup(data) {
  return {
    id: data.id,
    name: data.name,
    description: data.description,
    agentIds: data.agentIds,
  }
}
export function transformComputerGroupForServer(data) {
  return {
    name: data.name,
    description: data.description,
    agentIds: data.agentIds,
  }
}

export function transformBandwidthUtilization(bandwidthLimit) {
  return {
    id: bandwidthLimit.id,
    enableBandwidthSaver: bandwidthLimit.enableBandwidthSaver,
    maxDownloadSpeedFromInternet: bandwidthLimit.maxDownloadSpeedFromInternet,
    maxDownloadSpeedFromFileServer:
      bandwidthLimit.maxDownloadSpeedFromFileServer,
    maxDownloadSpeedInAgent: bandwidthLimit.maxDownloadSpeedInAgent,
  }
}

export function transformBandwidthUtilizationForServer(bandwidthLimit) {
  return {
    enableBandwidthSaver: bandwidthLimit.enableBandwidthSaver,
    maxDownloadSpeedFromInternet: bandwidthLimit.maxDownloadSpeedFromInternet,
    maxDownloadSpeedFromFileServer:
      bandwidthLimit.maxDownloadSpeedFromFileServer,
    maxDownloadSpeedInAgent: bandwidthLimit.maxDownloadSpeedInAgent,
  }
}
export function transformLanguage(data) {
  return {
    id: data.id,
    key: data.key,
    code: data.code,
    name: data.name,
  }
}

export function transformRelayServerConfiguration(relayServerConfig) {
  return {
    id: relayServerConfig.id,
    allowDownloadFromInternetInRemoteOffice:
      relayServerConfig.allowDownloadFromInternetInRemoteOffice,
    remoteOfficeIds: relayServerConfig.remoteOfficeIds,
  }
}

export function transformRelayServerConfigurationForServer(relayServerConfig) {
  return {
    allowDownloadFromInternetInRemoteOffice:
      relayServerConfig.allowDownloadFromInternetInRemoteOffice,
    remoteOfficeIds: relayServerConfig.remoteOfficeIds,
  }
}

export function transformAllLanguage(data) {
  return { languageMap: data.languageMap }
}

export function transformDeploymentNotification(patch) {
  return {
    ...(patch.flotoSchedule.timeInterval
      ? {
          type: patch.flotoSchedule.type,
          timeInterval:
            patch.flotoSchedule.timeIntervalUnit === 'days' &&
            patch.flotoSchedule.timeInterval === 1
              ? 24
              : patch.flotoSchedule.timeInterval,
          timeIntervalUnit: patch.flotoSchedule.timeIntervalUnit,
        }
      : {}),
    nextRunTime: patch.nextRunTime,
  }
}
export function transformDeploymentNotificationForServer(patch) {
  return {
    flotoSchedule: {
      type: patch.type,
      timeInterval: patch.timeInterval,
      timeIntervalUnit: 'hours',
    },
    nextRunTime: patch.nextRunTime,
  }
}
